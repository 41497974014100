import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TrackPackage from './components/TrackPackage';
import OrderDetails from './components/Orderdetails';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<TrackPackage />} />
        <Route path="/order-details/:orderId" element={<OrderDetails />} />
        <Route path="/order-details/:orderId" element={<OrderDetails />} />
        <Route path="/order/:orderId" element={<OrderDetails />} />

      </Routes>
    </Router>
  );
}

export default App;


// import React from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import TrackPackage from './components/TrackPackage';
// import OrderDetails from './components/Orderdetails';

// function App() {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<OrderDetails />} />
       
//       </Routes>
//     </Router>  
//   );
// }

// export default App;
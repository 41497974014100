import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';
import './styles.css';
import image1 from '../images/1img.png';
import image2 from '../images/2img.png';
import image4 from '../images/4img.png';
import comoditaLogo from '../images/comodita logo.png';

function ShipmentDetails({ orderData }) {
    const totalValue = orderData.details.reduce((total, item) => total + item.value, 0);
    const isMobile = window.innerWidth <= 767;

    return (
        <div className="container py-5" style={{ marginTop: '-50px', marginLeft: '-30px' }}>
            <div className="card shipment-facts border-0">
                <h4 className="shipment-facts-title mb-4" style={{ fontSize: isMobile ? '1.7rem' : '3rem' }}>Detaje të dërgesës</h4>
                <table className="shipment-table" style={{ fontSize: isMobile ? '0.8rem' : '1.2rem' }}>
                    <thead>
                        <tr>
                            <th style={{ width: '40%' }}>Artikulli</th>
                            <th style={{ textAlign: 'center' }}>Sasia</th>
                            <th style={{ textAlign: 'center' }}>Cmimi</th>
                            <th style={{ textAlign: 'center' }}>Vlera</th>
                        </tr>   
                    </thead>
                    <tbody>
                        {orderData.details.map((item, index) => (
                            <tr key={index}>
                                <td style={{ fontWeight: 'normal', textAlign: 'left', width: '40%' }}>{item.itemName}</td>
                                <td style={{ textAlign: 'center' }}>{item.quantity}</td>
                                <td style={{ textAlign: 'center' }}>{item.price.toFixed(2)}</td>
                                <td style={{ textAlign: 'center' }}>{item.value.toFixed(2)}</td>
                            </tr>
                        ))}
                        <tr>
                            <td colSpan="3" style={{ textAlign: 'left', fontWeight: 'bold' }}>Totali</td>
                            <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{totalValue.toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

function OrderDetails() {
    const [orderData, setOrderData] = useState(null);
    const [trackingNumber, setTrackingNumber] = useState('');
    const [isValidOrderId, setIsValidOrderId] = useState(true);
    const navigate = useNavigate();
    const { orderId } = useParams();

    useEffect(() => {
        async function fetchData() {
            try {
                // Fetch config.json
                const response = await fetch('/config.json');
                const config = await response.json();
                
                // Fetch order data using the base URL from config.json
                const orderResponse = await fetch(`${config.apiBaseUrl}${orderId}`);
                if (!orderResponse.ok) {
                    throw new Error('Failed to fetch order details');
                }
                const data = await orderResponse.json();
                setOrderData(data);
                setIsValidOrderId(true);
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsValidOrderId(false);
            }
        }
        
        fetchData();
    }, [orderId]);
    

    // const handleTrackButtonClick = (e) => {
    //     e.preventDefault();
    //     if (trackingNumber.trim() !== '') {
    //         navigate(`/order-details/${trackingNumber}`);
    //         // Delay scrolling to ensure navigation is completed
    //         setTimeout(() => {
    //             window.scrollTo({ top: 0, behavior: 'smooth', block: 'start' });
    //         }, 500); // Adjust the delay time as needed
    //     } else {
    //         alert("Please enter a tracking number.");
    //     }
    // };


    const handleTrackButtonClick = (e) => {
        e.preventDefault();
        if (trackingNumber.trim() !== '') {
            // navigate(`/order-details/${trackingNumber}`);
            navigate(`/order/${trackingNumber}`); 
            // Clear the input field
            setTrackingNumber('');
            const scrollToTop = () => {
                const currentPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
                if (currentPosition > 0) {
                    window.scrollTo(0, currentPosition - currentPosition / 20); // Adjust the divisor to slow down the scroll
                    window.requestAnimationFrame(scrollToTop);
                }
            };
            scrollToTop();
        } else {
            alert("Please enter a tracking number.");
        }
    };
    
    
    

    if (!isValidOrderId) {
        return (
            <div style={{ 
                height: '100vh',
                verticalAlign: 'middle',
                display: 'flex',
                fontFamily: "'Calibri', sans-serif",
                fontSize: '14px',
                transform: 'translate(-50%, -50%)',
                animation: 'slideIn 0.7s forwards'
            }}>
                <div style={{ 
                    margin: 'auto',
                    maxWidth: '450px',
                    boxShadow: '0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                    borderRadius: '16px'
                }}>
                    <div style={{ 
                        padding: '6vh 6vh 3vh 6vh'
                    }}>
                        <div style={{ 
                            display: 'flex',
                            alignItems: 'center',
                            verticalAlign: 'middle'
                        }}>
                            <h5 style={{ fontSize: '25px', marginBottom: '0', textAlign: 'center'}}><b>Your tracking number is invalid.</b></h5>
                        </div>
                        <div style={{ 
                            padding: '4vh 0 6vh',
                            textAlign: 'center',
                            marginBottom: '-30px'
                        }}>
                            <button className="btn btn-primary" style={{ 
                                width: '40%',
                                backgroundColor: 'rgb(255, 38, 0)',
                                borderColor: 'rgb(255, 38, 0)',
                                color: 'white',
                                padding: '1vh 0',
                                borderRadius: '8px'
                             }} onClick={() => navigate("/")}>Go Home</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (!orderData) {
        return null;
    }

    const getStatusProgress = () => {
        const status = orderData.header.status_Customer;
    
        if (status === "Pranuar") {
            return [true, false, false];
        } else if (status === "Ngarkuar") {
            return [true, true, false];
        } else if (status === "Dorëzuar" || status === "Dorezuar") {
            return [true, true, true];
        } else if (status === "Refuzuar") {
            return [false, false, false];
        } else if (status === "Anuluar") {
            return [false, false, false];
        } else {
            return [false, false, false];
        }
    };
    
    
    try {
        console.log(getStatusProgress());
    } catch (error) {
        console.error('Error:', error);
    }
    
    
    

    const translateDay = (englishDay) => {
        switch (englishDay) {
            case "Monday":
                return "E hënë";
            case "Tuesday":
                return "E martë";
            case "Wednesday":
                return "E mërkurë";
            case "Thursday":
                return "E enjte";
            case "Friday":
                return "E premte";
            case "Saturday":
                return "E shtunë";
            case "Sunday":
                return "E dielë";
            default:
                return englishDay;
        }
    };

    const [isStep1Active, isStep2Active, isStep3Active] = getStatusProgress();

 
    return (
        <div>
            {window.innerWidth <= 767 ? (
            <nav className="navbar navbar-expand-lg navbar-dark " style={{marginBottom: '-40px'}}>
                <div className="container-fluid">
                <img className="navbar-icon" style={{width: 'auto', height: '120px', margin: 'auto'}} src={comoditaLogo} alt="Order Processed" />
                
                </div>
            </nav>
            ):(
                <nav className="navbar navbar-expand-lg navbar-dark " style={{marginBottom: '-50px'}}>
                <div className="container-fluid">
                <img className="navbar-icon" style={{width: 'auto', height: '120px', margin: 'auto'}} src={comoditaLogo} alt="Order Processed" />
                
                </div>
            </nav> 
            )}
      
            <div className="container py-5" >
                <div className="card shipment-facts border-0">
                                            {/* Content from the second card */}
                    {window.innerWidth <= 767 ? (
                        <div className="card shipment-facts border-0">
                            <div className="row">
                                <div className="col-md-12">
                                <div className="px-3 top" style={{ fontSize: '1.5rem', marginLeft: '-20 px'}}>
                                            <div className="col-md-6 mt-n3">
                                                <p className="mb-0" style={{ fontSize: '1.0rem' }}>Statusi
                                                <span className="font-weight-bold" style={{ fontSize: '1.2rem', marginLeft: '10px', color: orderData.header.status_Customer === 'Dorezuar' || orderData.header.status_Customer === 'Dorëzuar' ? '#2E8B57' 
                                                : orderData.header.status_Customer === 'Refuzuar' || orderData.header.status_Customer === 'Anuluar' ? 'red' 
                                                : orderData.header.status_Customer === 'Ngarkuar' || orderData.header.status_Customer === 'Caktuar' ? '#651FFF' 
                                                : 'black' }}>{orderData.header.status_Customer}</span></p>
                                                <p style={{ fontSize: '1.0rem' }}>{translateDay(orderData.header.lAstStatusDay)}<br style={{ fontSize: '1.0rem' }} />{orderData.header.lastStatusDate}</p>
                                            </div>
                                            <div className="col-md-6 order-md-1" style={{marginTop: '20px'}}>
                                                <h5 style={{ fontSize: '1.0rem', marginLeft: '0' }}>Porosia</h5>
                                                <p className="text-primary font-weight-bold" style={{ fontSize: '1.0rem' }}>{orderData.header.orderNumber}</p>
                                                <p style={{ fontSize: '1.0rem', marginLeft: '0' }}>Numri gjurmues <span className="font-weight-bold" style={{ fontSize: '1rem' }}>{orderData.header.trackingNumber}</span></p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        ) : (
                        <div className="card shipment-facts border-0">
                            <div className="row">
                            <div className="col-md-12">
                                <div className="px-3 top" style={{ fontSize: '1.5rem' }}>
                                <div className="row">
                                    <div className="col-md-6 mt-n3">
                                    <p className="mb-0" style={{ fontSize: '1.3rem' }}>Statusi<span className="font-weight-bold" style={{ marginLeft: '20px', color: orderData.header.status_Customer === 'Dorezuar' || orderData.header.status_Customer === 'Dorëzuar' ? '#2E8B57' : orderData.header.status_Customer === 'Refuzuar' || orderData.header.status_Customer === 'Anuluar' ? 'red' : orderData.header.status_Customer === 'ngarkuar' || orderData.header.status_Customer === 'caktuar' ? '#651FFF' : 'black' }}>{orderData.header.status_Customer}</span></p>
                                    <p style={{ fontSize: '1.8rem' }}>{translateDay(orderData.header.lAstStatusDay)}<br style={{ fontSize: '1.0rem' }} />{orderData.header.lastStatusDate}</p>
                                    </div>
                                    <div className="col-md-6" >
                                    <h5 style={{ fontSize: '1.3rem', marginLeft: '78px' }}>Porosia<span className="text-primary font-weight-bold" style={{ fontSize: '1.3rem', marginLeft: '10px' }}>{orderData.header.orderNumber}</span></h5>
                                    <p style={{ fontSize: '1.3rem', marginLeft: '78px' }}>Nr gjurmues <span className="font-weight-bold" style={{ fontSize: '1rem' }}>{orderData.header.trackingNumber}</span></p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        )}





                    {/* Add class 'active' to progress */}
                    {window.innerWidth <= 767 ? ( 
                    <div className="row d-flex justify-content-center"> 
                        <div className="col-12">
                            {orderData.header.status_Customer === "Anuluar" && (
                                <p className="font-weight-bold" style={{textAlign: 'center', color: 'red'}} >Porosia juaj është anuluar!</p>
                            )}
                            {orderData.header.status_Customer === "Refuzuar" && (
                                <p className="font-weight-bold" style={{textAlign: 'center', color: 'red'}}>Porosia juaj është refuzuar!</p>
                            )}
                            <ul id="progressbar" className="text-center">
                                <li className={isStep1Active ? "active step0" : "step0"}></li>
                                <li className={isStep2Active ? "active step0" : "step0"}></li>
                                <li className={isStep3Active ? "active step0" : "step0"}></li>
                            </ul>
                        
                        </div>
                    </div>
                    ) : (
                        <div className="row d-flex justify-content-center"> 
                        <div className="col-12">
                            {orderData.header.status_Customer === "Anuluar" && (
                                <p className="font-weight-bold" style={{textAlign: 'center', color: 'red'}} >Porosia juaj është anuluar!</p>
                            )}
                            {orderData.header.status_Customer === "Refuzuar" && (
                                <p className="font-weight-bold" style={{textAlign: 'center', color: 'red'}}>Porosia juaj është refuzuar!</p>
                            )}
                            <ul id="progressbar" className="text-center">
                                <li className={isStep1Active ? "active step0" : "step0"}></li>
                                <li className={isStep2Active ? "active step0" : "step0"}></li>
                                <li className={isStep3Active ? "active step0" : "step0"}></li>
                            </ul>
                        
                        </div>
                    </div>
                    )}

                    
                



            {window.innerWidth <= 767 ? (                    
                <div className="height row justify-content-between top custom-gap">
                    
                        
                        <div className="d-flex flex-column ">
                        <img className="icon" src={image1} alt="Order Processed" style={{ width: '45px', height: 'auto' }} />
                            <p className="font-weight-bold" style={{fontSize: '11px'}}><br />Pranuar</p>
                        </div>
                    
                    
                       
                        <div className="d-flex flex-column ">
                        <img className="icon" src={image2} alt="Order Shipped"style={{ width: '45px', height: 'auto' }} />
                            <p className="font-weight-bold" style={{fontSize: '11px'}}><br />Ngarkuar</p>
                        </div>
                    
                    {orderData.header.status_Customer === "Refuzuar" ? (
                       
                          
                            <div className="d-flex flex-column ">
                                 <img className="icon" src={image4} alt="Order Arrived"style={{ width: '45px', height: 'auto' }} />
                                <p className="font-weight-bold" style={{fontSize: '11px'}}><br />Refuzuar</p>
                            </div>
                        
                    ) : orderData.header.status_Customer === "Anuluar" ? (
                        
                            
                            <div className="d-flex flex-column ">
                                 <img className="icon" src={image4} alt="Order Arrived"style={{ width: '45px', height: 'auto' }} />
                                <p className="font-weight-bold" style={{fontSize: '11px'}}><br />Anuluar</p>
                            </div>
                        
                    ) : (
                      
                         
                            <div className="d-flex flex-column ">
                                 <img className="icon" src={image4} alt="Order Arrived"style={{ width: '45px', height: 'auto' }} />
                                <p className="font-weight-bold" style={{fontSize: '11px'}}><br />Dorezuar</p>
                            </div>
                        
                    )}
                </div>
                ) : (
                    <div className="height row justify-content-between top custom-gap">
                    <div className="col-md-2 px-md-1 d-flex icon-content">
                        <img className="icon" src={image1} alt="Order Processed" />
                        <div className="d-flex flex-column ml-auto">
                            <p className="font-weight-bold"><br />Pranuar</p>
                        </div>
                    </div>
                    <div className="col-md-2 px-md-1 d-flex icon-content">
                        <img className="icon" src={image2} alt="Order Shipped" />
                        <div className="d-flex flex-column ml-auto">
                            <p className="font-weight-bold"><br />Ngarkuar</p>
                        </div>
                    </div>
                    {orderData.header.status_Customer === "Refuzuar" ? (
                        <div className="col-md-2 px-md-1 d-flex icon-content">
                            <img className="icon" src={image4} alt="Order Arrived" />
                            <div className="d-flex flex-column ml-auto">
                                <p className="font-weight-bold"><br />Refuzuar</p>
                            </div>
                        </div>
                    ) : orderData.header.status_Customer === "Anuluar" ? (
                        <div className="col-md-2 px-md-1 d-flex icon-content">
                            <img className="icon" src={image4} alt="Order Arrived" />
                            <div className="d-flex flex-column ml-auto">
                                <p className="font-weight-bold"><br />Anuluar</p>
                            </div>
                        </div>
                    ) : (
                        <div className="col-md-2 px-md-1 d-flex icon-content">
                            <img className="icon" src={image4} alt="Order Arrived" />
                            <div className="d-flex flex-column ml-auto">
                                <p className="font-weight-bold"><br />Dorezuar</p>
                            </div>
                        </div>
                    )}
                </div>
                )}

                </div>
            </div>



{/* ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}



            {window.innerWidth <= 767 ? (                   
            <div className="container py-5 " style={{marginTop: '-50px'}}>
                <div className="card shipment-facts border-0">
                        <h4 className="shipment-facts-title mb-4" style={{fontSize: '1.7rem'}}>Të dhënat e dërgesës</h4>
                        <table className="shipment-table" style={{ fontSize: '0.8rem' }}>
                            {/* Rest of the table content */}
                            <tr>
                                <td style={{ padding: '10px 12px' }}><span className="text-primary font-weight-bold">NUMRI GJURMUES</span></td>
                                <td style={{ padding: '10px 12px' }}>{orderData.header.trackingNumber}</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '10px 12px' }}><span className="text-primary font-weight-bold">DATA E POROSITJES</span></td>
                                <td style={{ padding: '10px 12px' }}>{orderData.header.orderDate}</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '10px 12px' }}><span className="text-primary font-weight-bold">DATA E NISJES</span></td>
                                <td style={{ padding: '10px 12px' }}>{orderData.header.ngarkuarDate}</td>
                            </tr>
                        </table>
                </div>
            </div>
            ):(
                <div className="container py-5">
                <div className="card shipment-facts border-0">
                        <h4 className="shipment-facts-title mb-4" style={{fontSize: '3rem'}}>Të dhënat e dërgesës</h4>
                        <table className="shipment-table" style={{ fontSize: '1.2rem' }}>
                            {/* Rest of the table content */}
                            <tr>
                                <td style={{ padding: '10px 12px' }}><span className="text-primary font-weight-bold">NUMRI GJURMUES</span></td>
                                <td style={{ padding: '10px 12px' }}>{orderData.header.trackingNumber}</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '10px 12px' }}><span className="text-primary font-weight-bold">DATA E POROSITJES</span></td>
                                <td style={{ padding: '10px 12px' }}>{orderData.header.orderDate}</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '10px 12px' }}><span className="text-primary font-weight-bold">DATA E NISJES</span></td>
                                <td style={{ padding: '10px 12px' }}>{orderData.header.ngarkuarDate}</td>
                            </tr>
                        </table>
                </div>
            </div> 
            )}

            {/* <div className="container py-5">
                <div className="card shipment-facts border-0">
                    <h4 className="shipment-facts-title mb-4">Detaje të dërgesës</h4>
                    <table className="shipment-table" style={{ fontSize: '1.2rem' }}>
                        <thead>
                            <tr>
                                <th style={{width: '40%'}}>Artikulli</th>
                                <th style={{textAlign: 'center'}}>Sasia</th>
                                <th style={{textAlign: 'center'}}>Cmimi</th>
                                <th style={{textAlign: 'center'}}>Vlera</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderData.details.map((item, index) => (
                                <tr key={index}>
                                    <td style={{fontWeight: 'normal', textAlign: 'left', width: '40%'}}>{item.itemName}</td>
                                    <td style={{textAlign: 'center'}}>{item.quantity}</td>
                                    <td style={{textAlign: 'center'}}>{item.price.toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{item.value.toFixed(2)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div> */}

<div className="container py-5" >
                
                <div className="card shipment-facts border-0">
                  
                        <ShipmentDetails orderData={orderData} />
              
                </div>
                </div>




                {window.innerWidth <= 767 ? (     
            <div className="container py-5" style={{  marginBottom: '0px', marginTop: '-50px'}}>
                <div className="card shipment-facts border-0"  > 
                <h4 className="shipment-facts-title mb-4" style={{fontSize: '1.7rem'}}>Të dhënat e dërgesës</h4>
                    <table className="shipment-table" style={{ fontSize: '0.8rem', marginTop: '10px' }}>
                        <thead>
                            <tr>
                                <th style={{width: '23%'}}>Statusi</th>
                                <th style={{textAlign: 'center', width: '23%'}}>Dita e përditësuar</th>
                                <th style={{textAlign: 'center', width: '23%'}}>Data e përditësuar</th>
                                <th style={{textAlign: 'center', width: '31%'}}>Shënim</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderData.history.map((status, index) => (
                                <tr key={index}>
                                    <td style={{width: '23%'}}>{status.status_Customer}</td>
                                    <td style={{textAlign: 'center', width: '23%'}}>{translateDay(status.updateDay)}</td> {/* Translate the update day */}
                                    <td style={{textAlign: 'center', width: '23%'}}>{translateDay(status.updateDate)}</td>
                                    <td style={{textAlign: 'center', width: '31%'}}>{status.note}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
                ):(
                    <div className="container py-5" style={{ marginTop: '0px', marginBottom: '0px' }}>
                    <div className="card shipment-facts border-0"  > 
                        <h4 className="shipment-facts-title mb-4" style={{fontSize: '3rem'}} >Historia</h4>
                        <table className="shipment-table" style={{ fontSize: '1.2rem', marginTop: '10px' }}>
                            <thead>
                                <tr>
                                    <th style={{width: '23%'}}>Statusi</th>
                                    <th style={{textAlign: 'center', width: '23%'}}>Dita e përditësuar</th>
                                    <th style={{textAlign: 'center', width: '23%'}}>Data e përditësuar</th>
                                    <th style={{textAlign: 'center', width: '31%'}}>Shënim</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderData.history.map((status, index) => (
                                    <tr key={index}>
                                        <td style={{width: '23%'}}>{status.status_Customer}</td>
                                        <td style={{textAlign: 'center', width: '23%'}}>{translateDay(status.updateDay)}</td> {/* Translate the update day */}
                                        <td style={{textAlign: 'center', width: '23%'}}>{translateDay(status.updateDate)}</td>
                                        <td style={{textAlign: 'center', width: '31%'}}>{status.note}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                )}
                


            {window.innerWidth <= 767 ? (                            
            <section className="track-package-section py-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <h2 className="mb-4" style={{  fontWeight: 'bold', fontSize: '1.5rem' }}>Dëshironi të gjurmoni porosi tjetër?</h2>
                            <form className="d-flex" onSubmit={handleTrackButtonClick}>
                                {/* Update input value and onChange handler */}
                                <input className="form-control me-2" type="search" placeholder="CD240527065524-XXXX" aria-label="Enter tracking number" style={{ color: 'black' }} value={trackingNumber} onChange={(e) => setTrackingNumber(e.target.value)} />
                                <button className="btn btn-primary" type="submit" style={{ marginLeft: '10px' }}>Gjurmo</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            ):(
                <section className="track-package-section py-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <h2 className="mb-4" style={{ marginRight: '100px' }}>Dëshironi të gjurmoni porosi tjetër?</h2>
                            <form className="d-flex" onSubmit={handleTrackButtonClick}>
                                {/* Update input value and onChange handler */}
                                <input className="form-control me-2" type="search" placeholder="CD240527065524-XXXX" aria-label="Enter tracking number" style={{ color: 'black' }} value={trackingNumber} onChange={(e) => setTrackingNumber(e.target.value)} />
                                <button className="btn btn-primary" type="submit" style={{ marginLeft: '10px' }}>Gjurmo</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            )}


                                          {/* <div>
            
            {orderData && (
                <div>
                    
                    <h2>Order Details</h2>
                    <p>Order Number: {orderData.header.orderNumber}</p>
                    <p>Tracking Number: {orderData.header.trackingNumber}</p>
                    <p>Order Date: {orderData.header.orderDate}</p>
                    

                    
                    <h2>Order Items</h2> 
                    <ul>
                        {orderData.details.map((item, index) => (
                            <li key={index}>
                                {item.itemName} - Quantity: {item.quantity}, Price: ${item.price}, Value: ${item.value}
                            </li>
                        ))}
                    </ul>

                
                    <h2>Order History</h2>
                    <ul>
                        {orderData.history.map((status, index) => (
                            <li key={index}>
                                {status.status} - Update Date: {status.updateDate}, Update Day: {status.updateDay}, Note: {status.note}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div> */}


            {/* Stunning Footer */}
            {/* <footer className="stunning-footer">
                <div className="footer-container container" style={{height: '0px'}}>
                    <div className="footer-col">
                        <h4>About Us</h4>
                        <ul>
                            <li><a href="#">Company</a></li>
                            <li><a href="#">Team</a></li>
                            <li><a href="#">Careers</a></li>
                            <li><a href="#">Privacy Policy</a></li>
                        </ul>
                    </div>
                    <div className="footer-col">
                        <h4>Services</h4>
                        <ul>
                            <li><a href="#">Design</a></li>
                            <li><a href="#">Development</a></li>
                            <li><a href="#">Marketing</a></li>
                            <li><a href="#">Strategy</a></li>
                        </ul>
                    </div>
                    <div className="footer-col">
                        <h4>Connect</h4>
                        <div className="social-links">
                            <a href="#"><i className="fab fa-facebook-f"></i></a>
                            <a href="#"><i className="fab fa-twitter"></i></a>
                            <a href="#"><i className="fab fa-instagram"></i></a>
                            <a href="https://www.comoditahome.com/ks/"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
            </footer> */}


        <footer className="simple-footer full-footer">
            <div className="footer-container">
                <div className="copyright">
                    Copyright © 2024 Comodita Home Kosova
                </div>
                <div className="links">
                    <a href="https://www.comoditahome.com/ks/" target="_blank" rel="noopener noreferrer">Site Map</a>
                    <a href="https://www.comoditahome.com/ks/termet-dhe-kushtet/" target="_blank" rel="noopener noreferrer">Terms of Use</a>
                    <a href="https://www.comoditahome.com/ks/politikat-e-privatesise/" target="_blank" rel="noopener noreferrer">Privacy & Security</a>
                </div>
                <div className="social-links">
                    <SocialIcon url="https://www.facebook.com/comoditahome" bgColor="transparent" target="_blank" rel="noopener noreferrer" />
                    <SocialIcon url="https://www.instagram.com/comoditahome" bgColor="transparent" target="_blank" rel="noopener noreferrer" />
                    <SocialIcon url="https://www.linkedin.com/company/comoditahome" bgColor="transparent" target="_blank" rel="noopener noreferrer" />
                    <SocialIcon url="https://www.youtube.com/channel/UC5O6IsT4JD4zaiOqmRi_Pvg" bgColor="transparent" target="_blank" rel="noopener noreferrer" />
                </div>
            </div>
        </footer>

        <footer className="simple-footer mobile-footer">
            <div className="footer-container">
                <div className="copyright">
                    Copyright © 2024 Comodita Home Kosova
                </div>
            </div>
        </footer>







        </div>
    );
}

export default OrderDetails;


//   style={{ display: window.innerWidth >= 767 ? 'block' : 'none' }}
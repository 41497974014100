import React, { useState } from 'react';
import travelimage from '../images/travel.png';

function TrackPackage() {
    const [trackingNumber, setTrackingNumber] = useState('');
    const [isValidOrderId, setIsValidOrderId] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const handleInputChange = (event) => {
        setTrackingNumber(event.target.value);
        if (event.target.value.trim() !== '') {
            setIsValidOrderId(true);
            setErrorMessage('');
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (trackingNumber.trim() === '') {
            setIsValidOrderId(false);
            setErrorMessage('Please enter a tracking number.');
        } else {
            setIsValidOrderId(true);
            // window.location.href = `/order-details/${trackingNumber}`;
            window.location.href = `/order/${trackingNumber}`;
        }
    };

    return (
        <div style={{ 
            height: '100vh',
            verticalAlign: 'middle',
            display: 'flex',
            fontFamily: "'Calibri', sans-serif",
            fontSize: '14px',
        }}>
            <div style={{ 
                margin: 'auto',
                maxWidth: '450px',
                boxShadow: '0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                borderRadius: '16px'
            }}>
                <div style={{ 
                    padding: '6vh 6vh 3vh 6vh'
                }}>
                    <div style={{ 
                        display: 'flex',
                        alignItems: 'center',
                        verticalAlign: 'middle'
                    }}>
                        <h5 style={{ fontSize: '25px', marginBottom: '0'}}><b>Gjurmo porosinë tënde</b></h5>
                        <img style={{ width: '100px', height: 'auto' }} src={travelimage} alt="Travel icon" />
                    </div>
                    <form id="trackForm" onSubmit={handleSubmit}>
                        <div style={{ 
                            margin: '4vh 0'
                        }}>
                            <label htmlFor="order_id" style={{ color: 'grey' }}>Order ID</label>
                            <input
                                type="text"
                                id="order_id"
                                value={trackingNumber}
                                onChange={handleInputChange}
                                placeholder="CD240527065524-XXXX"
                                style={{
                                    border: `1px solid ${isValidOrderId ? 'rgba(239, 239, 240, 1)' : 'red'}`, // Dynamic border color
                                    borderRadius: '6px', // Adds border radius
                                    padding: '0.75rem',
                                    outline: 'none',
                                    width: '100%',
                                    minWidth: 'unset',
                                    backgroundColor: 'transparent'
                                }}
                            />
                            {errorMessage && (
                                <div style={{ color: 'red', marginTop: '10px' }}>
                                    {errorMessage}
                                </div>
                            )}
                        </div>
                        <div style={{ 
                            padding: '4vh 0 6vh',
                            textAlign: 'center'
                        }}>
                            <button type="submit" style={{ 
                                width: '50%',
                                backgroundColor: 'rgb(255, 38, 0)',
                                borderColor: 'rgb(255, 38, 0)',
                                color: 'white',
                                padding: '1.5vh 0',
                                borderRadius: '8px'
                            }}>Track Order</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default TrackPackage;
